#body {
    display: flex;
    flex-direction: column;
    min-height: 55vh;
    background-color: #F4F8F9;
}

#footer {
    margin-top: auto;
    width: 100%;
}

#screenTopBodyText {
    font-size: large;
    color: black;
    font-weight: bold;
    width: fit-content;
    margin: auto;
    margin-top: 2vh;
}

#homeScreenMetadata {
    font-size: large;
    color: black;
    width: fit-content;
    margin-left: 25%;
    margin-right: 25%;
}

#homeScreenSummaryHeader {
    font-size: large;
    color: black;
    width: fit-content;
    margin-top: 5vh;
    margin-left: 25%;
    margin-right: 25%;
}

#homeScreenSummaryBody {
    font-size: large;
    color: black;
    width: fit-content;
    margin-left: 25%;
    margin-right: 25%;
}

#navBarCompanyName {
    font-weight: bold;
}

#navBarSelections {
    justify-content: left;
    padding-left: 20vw;
}

#navBarButtonSection {
    justify-content: right;
}

#navBarButton {
    margin-left: 1vw;
}

#navBarPassword {
    padding-left: 6vw;
}

.navbar-active {
    color: white !important;
}

#loginScreenBackground {
    background-color: #F8F8FC;
}

#loginScreenImage {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
    opacity: 0.75;
}